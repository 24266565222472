import React, { Component, Fragment } from 'react'
import { translate } from 'react-i18next'
import { Button } from '../../styled'

class Toggler extends Component {
  constructor(props) {
    super(props);
    const { i18n } = this.props;
    this.state = { 
      language: i18n.language, 
      open: false
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ language: nextProps.i18n.language });
  }

  componentDidMount() {
    this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
  }

  handleChangeLanguage(lng) {
    const { language, open } = this.state
    const { i18n } = this.props;
    if(lng === language) {
      this.setState({ open: !open });
    } else {
      i18n.changeLanguage(lng);
      this.setState({ open: false });
    }
  }

  handleOpen() {
    const { open } = this.state
    this.setState({ open: !open });
  }

  renderLanguage(lng) {
    const { language } = this.state
    return lng.code !== language ? (
      <Button
        key={lng.code}
        //
        onClick={() => this.handleChangeLanguage(lng.code)}
        fontSize={[4]}
        px={[4]}
        py={[3]}
      >
        {lng.label}
      </Button>
    ) : null
  }

  renderCurrent(languages) {
    const { language } = this.state
    const found = languages.find(lang => lang.code === language)
    if (found) {
      return (
        <Button
          key={found.code}
          variant="menu"
          onClick={() => this.handleChangeLanguage(found.code)}
          fontSize={[4]}
          px={[4]}
          py={[3]}
        >
          {found.label}
        </Button>
      )
    }
  }


  render() {
    const { open, language } = this.state
    const languages = [
      { code: 'de', label: 'DE' },
      { code: 'en', label: 'EN' }
    ]

    return (
      <Fragment>
        {/* {this.renderCurrent(languages)} */}
        {languages.map(lng => (
          this.renderLanguage(lng)
        ))}
      </Fragment>
    )
  }
}

export default translate()(Toggler);
