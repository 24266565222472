// styled-components based extension
import styled from 'styled-components'
import { Box } from '@magicsoup.io/stock'

const Line = styled(Box)`
  height: 4px;
  
  background-color: ${props => props.theme.colors.primary};
  width: 0%;
  transition: all 200ms linear;

  ${ props => props.hover && `
    transition: all 200ms linear;
    background-color: #000;
    width: 100%;
  `}
  
`

Line.defaultProps = {
  
}

export default Line