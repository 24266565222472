// styled-components based extension
import styled from 'styled-components'
import { Box } from '@magicsoup.io/stock'

const Section = styled(Box)`
`

Section.defaultProps = {
}

export default Section