// styled-components based extension
import styled from 'styled-components'
import { Flex } from '@magicsoup.io/stock'

const StyledModal = styled(Flex)`
  flex-direction: column;
`

const Section = styled(Flex)`

`
Section.defaultProps = {
  py: '1'
}

StyledModal.defaultProps = {
}

StyledModal.Section = Section

export default StyledModal