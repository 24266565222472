// styled-components based extension
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { variant } from 'styled-system'
import { Text } from '@magicsoup.io/stock'

const text = variant({ key: 'text' })

const TextVariant = styled(Text)(
  text
)

TextVariant.propTypes = {
  ...text.propTypes,
  as: PropTypes.string,
}

TextVariant.defaultProps = {
  as: 'p',
  m: 0,
  p: 0,
}

export default TextVariant