import { Link, StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { Icon } from '@zauberware/react-svg-assets'
import { Box, Flex, Text } from '@magicsoup.io/stock'
import { translate } from 'react-i18next'
import { ScrollToActionElement } from '@zauberware/react-scroll-to'
import Switch from '../Switch/Switch'

import {
  Button,
  Menu,
} from '../../styled'

import '../../i18n'
import Toggler from '../Switch/Toggler'

class Header extends Component {
  state = {
    hasScrolled: false,
    isToggled: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenScrollEvent)
  }

  listenScrollEvent = () => {
    if (window.scrollY > 10) {
      this.setState({ hasScrolled: true })
    } else {
      this.setState({ hasScrolled: false })
    }
  }

  handleToggle() {
    const { isToggled } = this.state
    this.setState({ isToggled: !isToggled })
  }

  render() {
    const { hasScrolled, isToggled } = this.state
    const bgColor = hasScrolled ? 'transparent' : 'white'

    return (
      <StaticQuery
        query={graphql`
          query HeaderQuery {
            generalJson {
              ctaButtonService
            }
            menuJson {
              items {
                title
                target
              }
            }
          }
        `}
        render={data => (
          <Fragment>
            <Menu.Wrap width={[1]} bg={bgColor} scrolled={hasScrolled} toggled={isToggled}>
              <Flex
                desktop
                className="desktop"
                alignItems="center"
                flexWrap="nowrap"
                width={[1 / 3]}
              >
                <Menu>
                  <Menu.Nav>
                    {
                      data.menuJson.items.map((item, index) => (
                        <ScrollToActionElement
                          target={item.target}
                          Parent={Menu.Item}
                          key={index}
                        >
                          <Text fontSize={[2, 2, 3, 4]}>{this.props.t(item.title)}</Text>
                        </ScrollToActionElement>
                      ))
                    }
                  </Menu.Nav>
                </Menu>
              </Flex>
              <Flex
                className="desktop"
                alignItems="center"
                justifyContent="center"
                p={[2]}
                width={[1 / 3]}
              >
                <Link to="/">
                  <Icon icon="logo" size={[4]} height="auto" />
                </Link>
              </Flex>
              <Flex
                className="desktop"
                width={[1 / 3]}
                justifyContent="flex-end"
                p={[2]}
              >
                <ScrollToActionElement
                  target="contact"
                  Parent={Button}
                  variant="primary"
                  fontSize={[2, 2, 2, 3]}
                >
                  {this.props.t(data.generalJson.ctaButtonService)}
                </ScrollToActionElement>
                <Switch />
              </Flex>
              <Flex
                className="mobile"
                alignItems="center"
                justifyContent="flex-start"
                p={[2]}
                width={[1 / 3]}
              >
                <Link to="/">
                  { isToggled
                    ? <Icon icon="logoToggled" size={[4]} height="auto" />
                    : <Icon icon="logo" size={[4]} height="auto" />
                    }
                </Link>
              </Flex>
              <Flex
                className="mobile"
                width={[1 / 3]}
                justifyContent="flex-end"
                p={[2]}
                toggled={isToggled}
              >
                <Box>
                  <input type="checkbox" id="hamburg" checked={isToggled} onClick={() => this.handleToggle()} onChange={() => this.handleToggle()} />
                  <label htmlFor="hamburg" className="hamburg">
                    <span className="line" />
                    <span className="line" />
                    <span className="line" />
                  </label>
                </Box>
              </Flex>
            </Menu.Wrap>
            <Menu.Overlay isToggled={isToggled}>
              <Menu.Nav>
                {
                  data.menuJson.items.map((item, index) => (
                    <ScrollToActionElement
                      target={item.target}
                      Parent={Menu.Item}
                      key={index}
                      px={[0]}
                    >
                      <Text
                        onClick={() => this.handleToggle()}
                        fontSize={[5]}
                        py={[4]}
                        px={[0]}
                      >
                        {this.props.t(item.title)}
                      </Text>
                    </ScrollToActionElement>
                  ))
                }
              </Menu.Nav>
              <Box py={[4]}>
                <ScrollToActionElement
                  Parent={Button}
                  target="contact"
                  variant="menu"
                  fontSize={[4]}
                  px={[4]}
                  py={[3]}
                  onMouseUp={() => this.handleToggle()}
                >
                  {this.props.t(data.generalJson.ctaButtonService)}
                </ScrollToActionElement>
              </Box>
              <Box py={[4]}>
                <Toggler />
              </Box>
            </Menu.Overlay>
          </Fragment>
        )}
      />
    )
  }
}


Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: '',
}

export default translate('header')(Header)
