// styled-components based extension
import styled, { css } from 'styled-components'
import { Box } from '@magicsoup.io/stock'

const MobileDecider = styled(Box)(props => css`
  
  ${props.desktop && `
    @media (max-width: 768px) {
      display: none;
    }
    @media (min-width: 768px) {
      display: flex;
    }
  `}

  ${props.mobile && `
    @media (max-width: 768px) {
      display: flex;
    }
    @media (min-width: 768px) {
      display: none;
    }
  `}
`)

MobileDecider.defaultProps = {

}

export default MobileDecider
