// styled-components based extension
import styled from 'styled-components'
import { Box } from '@magicsoup.io/stock'

const Close = styled(Box)`
  white-space: nowrap;
  position: absolute;
  right: 12px;
  top: 12px;
  
  label.munich { 
    display: block;
    width: 36px; height: 36px; 
    position: relative; 
    margin-left: auto; margin-right: auto;
    border-radius: 4px; 
  }
 
  #munich {display:none}

  label {
    .line { 
      position: absolute; 
      left:2px;
      height: 4px; 
      width: 32px; 
      background: #000; 
      border-radius: 2px;
      display: block; 
      transition: 0.5s; 
      transform-origin: center; 
   }
   
    .line:nth-child(1) { top: 6px; }
    .line:nth-child(2) { top: 16px; }
    .line:nth-child(3) { top: 26px; }
  }

  #munich:checked + label.munich .line:nth-child(1){
    transform: translateY(10px) rotate(-45deg);
    background: #000;
  }

  #munich:checked + label.munich .line:nth-child(2){
    opacity:0;
  }

  #munich:checked + label.munich .line:nth-child(3){
    transform: translateY(-10px) rotate(45deg);
    background: #000;
  }
`


Close.defaultProps = {
  as: 'form',
  cursor: 'pointer',
}


export default Close