import React, { Component } from "react";

import { translate } from "react-i18next";
import { Icon } from '@zauberware/react-svg-assets'

import { Flex, Text } from '@magicsoup.io/stock'

import { LanguageSwitch } from '../../styled'

class Switch extends Component {
  constructor(props) {
    super(props);
    const { i18n } = this.props;
    this.state = { 
      language: i18n.language, 
      open: false
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ language: nextProps.i18n.language });
  }

  componentDidMount() {
    this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
  }

  handleChangeLanguage(lng) {
    const { language, open } = this.state
    const { i18n } = this.props;
    if(lng === language) {
      this.setState({ open: !open });
    } else {
      i18n.changeLanguage(lng);
      this.setState({ open: false });
    }
  }

  handleOpen() {
    const { open } = this.state
    this.setState({ open: !open });
  }

  renderLanguage(lng) {
    const { language } = this.state
    return lng.code !== language ? (

      <Text
        key={lng.code}
        onClick={() => this.handleChangeLanguage(lng.code)}
        p={[2]}
      >
        {lng.label}
      </Text>
    ) : null
  }

  renderCurrent(languages) {
    const { language } = this.state
    const found = languages.find(lang => lang.code === language)
    if (found) {
      return (
        <Text
        key={found.code}
        onClick={() => this.handleChangeLanguage(found.code)}
        p={[2]}
      >
        {found.label}
      </Text>
      )
    }
  }


  render() {
    const { open, language } = this.state
    const languages = [
      { code: "de", label: "DE" },
      { code: "en", label: "EN" }
    ];
    
    return (
      <LanguageSwitch>
        <Flex p={[2]}>
          <Icon icon="caret" size="0" rotate={open ? "0" : "180"} css={{float: 'left'}} onClick={() => this.handleOpen()} />
        </Flex>
        <LanguageSwitch.Menu flexDirection="column" open={open}>
          {this.renderCurrent(languages)}
          {languages.map(lng => (
              this.renderLanguage(lng)
          ))}
        </LanguageSwitch.Menu>
      </LanguageSwitch>
    );
  }
}

export default translate()(Switch);
