// styled-components based extension
import styled from 'styled-components'
import { Text } from '@magicsoup.io/stock'

const Testimonial = styled(Text)`
  
  font-weight: 500;
  quotes: "»" "«";
  :before {
    content: open-quote;
  }
  :after {
    content: close-quote;
  }
`

Testimonial.defaultProps = {
  color: '#fff',
  as: 'p',
  m: 0,
  p: 0,
}

export default Testimonial