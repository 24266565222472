import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Flex, Text, Heading } from '@magicsoup.io/stock'
import { translate } from 'react-i18next'

import {
  StyledModal
} from '../../styled'

class ImprintModal extends Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query ImprintModalQuery {
            modalsJson {
              generalAddress
              generalPhone
              generalFax
              generalMail
              generalInternet
              generalManagement
              generalTax
              generalVAT
              imprintTitle
              imprintAddressTitle
              imprintAddressSubline
              imprintAddressStreet
              imprintAddressCity
              imprintAddressCountry
              imprintPhone
              imprintFax
              imprintEmail
              imprintInternet
              imprintManagement
              imprintVBGLInfo
              imprintRegister
              imprintLiability
              imprintAdministration
              imprintAdministrationRegister
              imprintAdministrationManagement
              imprintDataTaxId
              imprintDetailVATId
            }
          }
        `}
        render={(data) => (
          <StyledModal>
            <Flex flexDirection="column">
              <Heading as="h1">{this.props.t(data.modalsJson.imprintTitle)}</Heading>
              <StyledModal.Section flexWrap="wrap">
                <Flex width={[1, 1/3, 1/3, 1/3]} pb={1}>
                  <Text>{this.props.t(data.modalsJson.generalAddress)}</Text>
                </Flex>
                <Flex flexDirection="column">
                  <Text>{this.props.t(data.modalsJson.imprintAddressTitle)}</Text>
                  <Text>{this.props.t(data.modalsJson.imprintAddressStreet)}</Text>
                  <Text>{this.props.t(data.modalsJson.imprintAddressCity)}</Text>
                  <Text>{this.props.t(data.modalsJson.imprintAddressCountry)}</Text>
                </Flex>
              </StyledModal.Section>
              <StyledModal.Section>
                <Flex width={[1/3]}>
                  <Text>{this.props.t(data.modalsJson.generalPhone)}</Text>
                </Flex>
                <Flex flexDirection="column">
                  <Text>{this.props.t(data.modalsJson.imprintPhone)}</Text>
                </Flex>
              </StyledModal.Section>
              <StyledModal.Section>
                <Flex width={[1/3]}>
                  <Text>{this.props.t(data.modalsJson.generalFax)}</Text>
                </Flex>
                <Flex flexDirection="column">
                  <Text>{this.props.t(data.modalsJson.imprintFax)}</Text>
                </Flex>
              </StyledModal.Section>
              <StyledModal.Section>
                <Flex width={[1/3]}>
                  <Text>{this.props.t(data.modalsJson.generalMail)}</Text>
                </Flex>
                <Flex flexDirection="column">
                  <Text>{data.modalsJson.imprintEmail}</Text>
                </Flex>
              </StyledModal.Section>
              <StyledModal.Section>
                <Flex width={[1/3]}>
                  <Text>{this.props.t(data.modalsJson.generalInternet)}</Text>
                </Flex>
                <Flex flexDirection="column">
                  <Text>{data.modalsJson.imprintInternet}</Text>
                </Flex>
              </StyledModal.Section>
              <StyledModal.Section flexWrap="wrap">
                <Flex width={[1, 1/3, 1/3, 1/3]} pb={1}>
                  <Text>{this.props.t(data.modalsJson.generalManagement)}</Text>
                </Flex>
                <Flex flexDirection="column" flexGrow={1}>
                  <Text>{data.modalsJson.imprintManagement}</Text>
                </Flex>
              </StyledModal.Section>
            </Flex>
            <StyledModal.Section>
              <Text>{this.props.t(data.modalsJson.imprintVBGLInfo)}</Text>
            </StyledModal.Section>
            <StyledModal.Section flexDirection="column" py="2">
              <Text>{this.props.t(data.modalsJson.imprintAddressTitle)}</Text>
              <Text>{this.props.t(data.modalsJson.imprintAddressStreet)} | {this.props.t(data.modalsJson.imprintAddressCity)} | {this.props.t(data.modalsJson.imprintAddressCountry)}</Text>
              <Text>{this.props.t(data.modalsJson.imprintRegister)}</Text>
            </StyledModal.Section>
            <StyledModal.Section flexDirection="column" py="2">
              <Text>{this.props.t(data.modalsJson.imprintLiability)}</Text>
              <Text>{this.props.t(data.modalsJson.imprintAdministration)}</Text>
              <Text>{this.props.t(data.modalsJson.imprintAdministrationManagement)}</Text>
            </StyledModal.Section>
            <StyledModal.Section>
              <Flex width={[1/3]}>
                <Text>{this.props.t(data.modalsJson.generalTax)}</Text>
              </Flex>
              <Flex flexDirection="column">
                <Text>{data.modalsJson.imprintDataTaxId}</Text>
              </Flex>
            </StyledModal.Section>
            <StyledModal.Section>
              <Flex width={[1/3]}>
                <Text>{this.props.t(data.modalsJson.generalVAT)}</Text>
              </Flex>
              <Flex flexDirection="column">
                <Text>{data.modalsJson.imprintDetailVATId}</Text>
              </Flex>
            </StyledModal.Section>
          </StyledModal>
        )}
      />
    )
  }
}

export default translate('legal')(ImprintModal)
