import styled, { css } from 'styled-components'
import { Box } from '@magicsoup.io/stock'

const SliderWrap = styled(Box)`
  .slick-current {
    .menuElement {
      color: ${props => props.theme.colors.hover};
      :after {
        border-bottom: solid 3px ${props => props.theme.colors.hover};
        box-sizing: border-box;
        content: '';
        display: flex;
        flex-grow: 1;
        padding-bottom: 8px;
        width: 100%;
        transform: scaleX(1);
      }
    }
  }
`

export default SliderWrap
