// styled-components based extension
import React from 'react'
import styled from 'styled-components'
import { Link as MagicLink } from '@magicsoup.io/stock'

import theme from '../theme'

const Link = styled(MagicLink)`
  text-decoration: none;
`

Link.defaultProps = {
  m: 0,
  p: 0,
  target: '_blank',
  as: 'a',
  fontWeight: 400,
  color: 'white',
  letterSpacing: 'normal',
}

export default Link
