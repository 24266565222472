// styled-components based extension
import styled from 'styled-components'
import { Box } from '@magicsoup.io/stock'

const StoryElement = styled(Box)`

// &:nth-child(2) {
//   margin-top: 50%;
//   transform: translateY(-50%);
//   background-color: blue;
// }
//   &:nth-child(3) {
//     margin-top: -96px;
    
//   }

  // @media (max-width: 767px) {

  //   &:nth-child(2) {
  //     margin-top: 0;
  //   }
  //   &:nth-child(3) {
  //     margin-top: 0;
  //   }

  // }

`

StoryElement.defaultProps = {
  as: 'div'
}

export default StoryElement
