import { StaticQuery, graphql } from 'gatsby'
import uniqid from 'uniqid'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { Flex, Text, Heading } from '@magicsoup.io/stock'
import { translate } from 'react-i18next'

import {
  Button,
  Menu,
  StyledModal
} from '../../styled'

class PrivacyPolicy extends Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query PrivacyModalQuery {
            privacyJson {
              privacyTitle
              sections {
                title
                paragraphs {
                  subtitle
                  subsections {
                    title
                    content
                  }
                  content
                  htmlContent
                }
              }
            }
          }
        `}
        render={(data) => (
          <StyledModal>
            <Flex flexDirection="column">
              <Heading as="h1">{this.props.t(data.privacyJson.privacyTitle)}</Heading>
              { data.privacyJson.sections.map((section,index) => (
                <StyledModal.Section flexDirection="column" key={uniqid()}>
                  <Flex>
                   <Heading as="h2">{this.props.t(section.title)}</Heading>
                  </Flex>
                  { section.paragraphs.map((paragraph, ind) => (
                    <Fragment key={uniqid()}>
                      <Heading as="h3">{this.props.t(paragraph.subtitle)}</Heading>
                      <Flex flexDirection="column">
                        { paragraph.content &&
                          <Text textAlign="justify">{this.props.t(paragraph.content)}</Text>
                        }
                        { paragraph.htmlContent &&
                          <Text textAlign="justify" dangerouslySetInnerHTML={{__html: `${this.props.t(paragraph.htmlContent)}`}} />
                        }
                        { paragraph.subsections &&
                          <Fragment>
                            { paragraph.subsections.map((sub, im) => (
                              <Fragment key={uniqid()}>
                                <Heading as="h4">{this.props.t(sub.title)}</Heading>
                                <Text textAlign="justify">{this.props.t(sub.content)}</Text>
                              </Fragment>
                            ))}
                          </Fragment>
                        }
                      </Flex>
                    </Fragment>
                  ))}
                </StyledModal.Section>
              ))}
            </Flex>
          </StyledModal>
        )}
      />
    )
  }
}

export default translate('legal')(PrivacyPolicy)
