// styled-components based extension
import styled from 'styled-components'
import { Box } from '@magicsoup.io/stock'

const HeadlineAnimation = styled(Box)`
  

  ${ props => props.hidden && `
    opacity: 0;
    transform: translate3d(0,10px,0);
    transition: opacity 0.35s, transform 0.35s;
  `}
  
  ${ props => props.in && `
    opacity: 1;
    transform: translate3d(0,0,0);
    transition: opacity 0.35s, transform 0.35s;
  `}
  
  ${ props => props.out && `
    height: 0;
    display: none; 
    transition: height 0.35s;
  `}
`

HeadlineAnimation.defaultProps = {
  
}

export default HeadlineAnimation