// styled-components based extension
import styled from 'styled-components'
import { Button as MagicButton } from '@magicsoup.io/stock'

const Button = styled(MagicButton)`
  white-space: nowrap;
  :focus, :active {
    outline: 0;
  }
`


Button.defaultProps = {
  as: 'button',
  cursor: 'pointer',
  variant: 'primary',
  fontSize: 0,
  fontWeight: 700,
  borderRadius: 50,
  py: 2,
  px: 2,
}


export default Button