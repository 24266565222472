import React, { Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Icon } from '@zauberware/react-svg-assets'
import { translate } from 'react-i18next';

import {
  Flex,
  Box,
  Heading,
  Text,
} from '@magicsoup.io/stock'

class Features extends React.Component {  
  render() {
    return(
      <StaticQuery
        query={graphql`
          query FeaturesQuery {
            generalJson {
              featuresTitle
              featuresHeadline
              featureItems {
                icon
                title
                detail
              }
            }
          }`
        }
        render={ (data) => (
          <Fragment>
            <Box pb={[6]}>
              <Heading as="h1" variant="h1" fontSize={[7,7,11,14]}>{this.props.t(data.generalJson.featuresHeadline)}</Heading>
            </Box>
            <Flex flexWrap='wrap' justifyContent='center'> 
              { data.generalJson.featureItems.map( (feature, index) => (
                <Flex width={[1, 1/2, 1/4, 1/4]} flexDirection="column" alignItems="center" key={index}  px={[6,4,4,2]} py={[2,4,2]} >
                  <Icon icon={feature.icon} size={5} />
                  <Heading as="h3" variant="h3" textAlign='center' fontSize={[4,4,5,7]}>{this.props.t(feature.title)}</Heading>
                  <Text width={[1]} fontFamily='secondary' fontWeight='400' textAlign='center' fontSize={[2,2,2,4]}>{this.props.t(feature.detail)}</Text>
                </Flex>
              ))}
            </Flex>
          </Fragment>
        )}
      />
    )
  }
}

export default translate('features')(Features)
