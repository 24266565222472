// styled-components based extension
import React from 'react'
import styled from 'styled-components'
import P from './P'

const Subtitle = styled(P)`
  line-height: 1.3;
`

Subtitle.defaultProps = {
  fontSize: [4, 5],
  fontWeight: 300,
  color: 'font',
  mt: 2,
  mb: 5
}

export default Subtitle
