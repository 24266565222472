// styled-components based extension
import styled, { css } from 'styled-components'
import { Flex, Box, Text } from '@magicsoup.io/stock'

const StyledForm = styled(Flex)`
  flex-direction: column;
  width: 100%;
`

const Section = styled(Flex)`
  flex-grow: 1;
  
`

const InputGroup = styled(Flex)`
  display: inline-flex;
  flex-grow: 1;
  position: relative;
`
const ErrorMessage = styled(Text)`
  display: none;
  ${ props => props.error && `
    color: red;
    display: block;
    position: absolute;
    text-align: center;
    width: 100%;
    top: -3px;
  `}

  ${ props => props.errorText && `
    color: red;
    display: flex;
    flex: 1 0 100%;
  `}
`
const Label = styled.label( props => css`
  display: flex;
  align-items: center;
  ${ props.checkbox && `
  :hover {
    cursor: pointer;
  }
    ${Input} {
      flex-shrink: 0;
      border: none;
      min-width: unset;
      margin: 5px;
      :active, :focus {
        outline: none;
        border-bottom: none;
      }
      display: flex;
    }
    ${Text} {
      display: flex;
      color: ${ props.theme.colors.darkGray};
      flex-shrink: 1;
    }
  `}
  ${ props.error && `
    ${Text} {
      color: red;
    }
  `}
`)

const Input = styled.input( props => css`
  background-color: transparent;
  border: none;
  border-bottom: 2px solid black;
  padding: 0;
  min-width: 320px;
  text-align: center;
  
  :active, :focus {
    outline: none;
    border-bottom: 2px solid ${ props => props.theme.colors.secondary};
  }

  ${ props.textarea && `
    width: ${props.width || 'auto'};  
  `}

  ${ props.error && `
    border-bottom: 2px solid red;
    color: red;
  `}
`)


StyledForm.defaultProps = {
  as: 'div',
}

ErrorMessage.defaultProps = {
  as: 'span',
}

InputGroup.defaultProps = {
  as: 'span',
}
StyledForm.Label = Label
StyledForm.Input = Input
StyledForm.InputGroup = InputGroup
StyledForm.ErrorMessage = ErrorMessage
StyledForm.Section = Section

export default StyledForm 