import React, { Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Icon } from '@zauberware/react-svg-assets'
import { translate } from 'react-i18next'


import {
  Flex,
  Box,
  Heading,
} from '@magicsoup.io/stock'

import { Square, Button, Line, IconAnimation, TextAnimation, HeadlineAnimation } from '../../styled'

class Competences extends React.Component {
  state = {
    active: -1,
    hover: -1
  }

  onButtonClick( index ) {
    const { active } = this.state
    const set = active === index ? -1 : index
    this.setState({active: set})
  }

  enterHandler( index ) {
    const { hover } = this.state
    this.setState({hover: index})
  }

  leaveHandler( index ) {
    const { hover } = this.state
    if( hover === index ) {
      this.setState({hover: -1})
    }
  }

  isHover( index ) {
    const { hover } = this.state
    return hover === index
  }

  isActive( index ) {
    const { active } = this.state
    return active === index
  }
  
  render() {
    return(
      <StaticQuery
        query={graphql`
          query CompetencesQuery {
            generalJson {
              competencesTitle
              competencesHeadline
              competencesCards {
                icon
                iconFollow
                title
                detail
              }
            }
          }`
        }
        render={ (data) => (
          <Flex px={[5]} width={[1,1,1,5/6]} flexDirection='column' >
            <Box pb={[6]}>
              <Heading as="h2" variant="h2" fontSize={[4,5,5,9]}>{this.props.t(data.generalJson.competencesTitle)}</Heading>
              <Heading as="h1" variant="h1" fontSize={[9,15,15,22]}>{this.props.t(data.generalJson.competencesHeadline)}</Heading>
            </Box>
            <Flex flexWrap='wrap' justifyContent='center' m={[0, 0, -3, -3]}>
              { data.generalJson.competencesCards.map( (card, index) => (
                <Square width={[1, 1/2, 1/3, 1/3]} p={[3]} key={index}
                  onMouseEnter={() => this.enterHandler(index)}
                  onMouseLeave={() => this.leaveHandler(index)}
                >
                  <Square.Container>
                    <Square.Card front bg='primary' p={[3]}>
                      <Flex>
                        <Icon icon={card.icon} size={[3]} />
                        <Flex flex='1 0' px={4} alignItems='center'>
                          <Line hover={this.isHover(index) || this.isActive(index)}/>
                        </Flex>
                        <IconAnimation hover={this.isHover(index) || this.isActive(index)}>
                          <Icon icon={card.iconFollow} size={[3]} />
                        </IconAnimation>
                      </Flex>
                      <HeadlineAnimation out={this.isActive(index)}>
                        <Heading as="h3" variant="h3" bg='primary' variant="competence" pt={[2]} fontSize={[9,9,14,21]}>{this.props.t(card.title)}</Heading>
                      </HeadlineAnimation>
                      <HeadlineAnimation in={this.isActive(index) ? 'true' : ''} hidden={!this.isActive(index)}>
                        <Heading as="h3" variant="h3" bg='primary' variant="competence" pt={[2]} fontSize={[3,6,6,12]}>{this.props.t(card.detail)}</Heading>
                      </HeadlineAnimation>
                      <Button variant='block' px={[3]} py={[2]} css={{alignSelf: 'flex-start'}} mt="auto" onClick={() => this.onButtonClick(index)}>{this.isActive(index) ? this.props.t('weniger') : this.props.t('mehr')}</Button>
                    </Square.Card>
                  </Square.Container>
                </Square>
              ))}
            </Flex>
          </Flex>
        )}
      />
    )
  }
}

export default translate('competences')(Competences)
