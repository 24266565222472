// styled-components based extension
import styled from 'styled-components'
import { Image } from '@magicsoup.io/stock'

const ContactImage = styled(Image)`
  max-width: 100%;
  border-radius: 50%;
  display: inline;
  height: 66%;
`

ContactImage.defaultProps = {
  m: 0,
  as: 'img',
}

export default ContactImage
