import React, { Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Icon } from '@zauberware/react-svg-assets'
import { translate } from 'react-i18next'

import TextLoop from 'react-text-loop'
import { TestimonialSlider } from '../../components'

import {
  Flex,
  Box,
  Heading,
  Text,
  Image,
} from '@magicsoup.io/stock'

import { TextVariant } from '../../styled'

class About extends React.Component {  
  render() {
    return(
      <StaticQuery
        query={graphql`
          query AboutQuery {
            generalJson {
              claims {
                move
                beautiful
                alternatives
              }
              aboutTitle
              aboutHeadline
              aboutSubtitle
              aboutContent
            }
          }`
        }
        render={ (data) => (
          <Fragment>
            <Heading as="h2" variant="h2" fontSize={[4,5,5,9]}>{this.props.t(data.generalJson.aboutTitle)}</Heading>
            <Heading as="h1" variant="h1" fontSize={[9,15,15,22]}>{this.props.t(data.generalJson.aboutHeadline)}</Heading>
            <Box 
              p={[3]}
              pt={[9]}
              pr={[6]}
              >
              <Heading as="h2" variant="hero" fontSize={[26,26,28,29]}>{this.props.t(data.generalJson.claims.move)}</Heading>
              <TextLoop>
                  <Heading as="h2" variant="hero" fontSize={[26,26,28,29]}>{this.props.t(data.generalJson.claims.beautiful)}</Heading>
                  { data.generalJson.claims.alternatives.map(( alt, index ) => (
                    <Heading as="h2" variant="hero" fontSize={[26,26,28,29]} key={index}>{this.props.t(alt)}</Heading>
                  ))}
                </TextLoop>
            </Box>
            <Flex flexWrap='wrap'>
              <Box 
                bg='secondary'
                width={[1, 1/2, 1/3]}
              >
                <TestimonialSlider />
              </Box>
              <Box 
                bg='lightGray'
                py={[3]}
                px={[2]}
                width={[1, 1/2, 2/3]}
              >
                <Box width={[1, 1, 2/3]} p={[3]} >
                  <TextVariant variant="headline" py={[2]} fontSize={[4,4,7,9]}>{this.props.t(data.generalJson.aboutSubtitle)}</TextVariant>
                  <TextVariant variant="running" fontSize={[2,2,5,8]}>{this.props.t(data.generalJson.aboutContent)}</TextVariant>
                </Box>
              </Box>
            </Flex>
          </Fragment>
        )}
      />
    )
  }
}

export default translate('about')(About)
