import React, { Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'

import lottie from 'lottie-web'
import { TimelineLite, TimelineMax, Linear } from 'gsap';
import gg_animation from './gg-header-animation-logo.json'

import { Icon } from '@zauberware/react-svg-assets'
import { translate } from 'react-i18next'
import { ScrollToActionElement } from '@zauberware/react-scroll-to'

import {
  Flex,
  Box,
  Heading
} from '@magicsoup.io/stock'

import {
  Button,
} from '../../styled'
import { pointer } from 'popmotion';

class Hero extends React.Component {

  componentDidMount() {
    if (this._gg_animation) {
      this._gg_animation.goToAndPlay(0, false)
    } else {
      this._checkAnimationGga()
    }   
  }

  componentDidUpdate() {
    this._checkAnimationGga()
  }

  _checkAnimationGga = () => {
    if (this._gga && !this._gg_animation) {
      this._gg_animation = lottie.loadAnimation({
        container: this._gga,
        renderer: 'svg',
        animationData: gg_animation,
        loop: true,
      })
    }
  }

  _clickGga = () => {
    if (this._gg_animation) {
      this._gg_animation.goToAndPlay(0, false)
    }
  }

  render() {

    

    return(
      <StaticQuery
        query={graphql`
          query HeroQuery {
            generalJson {
              claims {
                move
                beautiful
                alternatives
              }
              ctaButtonOffer
            }
          }`
        }
        render={ (data, t) => (
          <Fragment>
            <Flex 
                justifyContent='center'
                desktop
                className='desktop'
                width={[1]}
                height={[1]}
              >
                {/* pb={[2,4,4,4]} */}
                <Box 
                  width={[7/12]}
                  pb={['50px']}
                  pt={[8]}
                  id="gga"
                  ref={gga => (this._gga = gga)}
                  style={{
                    // position: 'absolute',
                    width: 800,
                    height: 500,
                    cursor: 'pointer',
                    // top: 0,
                    // left: 0,
                  }}
                  onClick={this._clickGga}
                />
              </Flex>    
            <Flex flexWrap='wrap'>
              <Box
                width={[1, 2/3, 2/3]}
                bg='primary'
                pt={[6,8,9]}
                pr={[7]}
                pb={[2]}
                pl={[2]}
                >
                <Heading as="h5" variant="hero" fontSize={['50px', '65px', '110px']}>{this.props.t(data.generalJson.claims.move)}</Heading>
                <Heading as="h5" variant="hero" fontSize={['50px', '65px', '110px']}>{this.props.t(data.generalJson.claims.beautiful)}.</Heading>
              </Box>
              <Flex
                width={[1, 1/3, 1/3]}
                bg='lightGray'
                p={[2]}
              >
                <Flex 
                  flex="1 0 100%"
                  alignItems="flex-end"
                  justifyContent="flex-end"
                  p={[3]} 
                >
                <ScrollToActionElement
                  target='contact'
                  Parent={Button}
                  variant='primary'
                  fontSize={[2,2,2,3]}
                >
                  {this.props.t(data.generalJson.ctaButtonOffer)}
                </ScrollToActionElement>
                </Flex>
              </Flex>
            </Flex>
          </Fragment>
        )}
      />
    )
  }
}

export default translate('hero')(Hero)
