export { default as Layout } from './Layout'
export { default as SEO } from './Seo'
export { default as Header } from './Header'
export { default as Hero } from './Hero'
export { default as Contact } from './Contact'
export { default as ContactForm } from './ContactForm'
export { default as Competences } from './Competences'
export { default as CookieDisplay } from './CookieDisplay'
export { default as Features } from './Features'
export { default as About } from './About'
export { default as TestimonialSlider } from './Slider/TestimonialSlider'
export { default as StorySlider } from './Slider/StorySlider'
export { default as Switch } from './Switch'
export { default as Footer } from './Footer'
