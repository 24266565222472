// styled-components based extension
import React from 'react'
import styled from 'styled-components'
import { Flex } from '@magicsoup.io/stock'

import theme from '../theme'

const LanguageSwitch = styled(Flex)`
  display: inline-flex;
  height: 0;
	&:hover {
    cursor: pointer;
  }
`
const Menu = styled(Flex)`
  background-color: #fff;
  flex-direction: column;
  height: 32px;
  overflow: hidden;
  ${ props => props.open && `
    height: 64px;
    
  `}
`

LanguageSwitch.Menu = Menu

LanguageSwitch.defaultProps = {
  as: 'div',
  m: 0,
  p: 0,
  color: 'font'
}

export default LanguageSwitch