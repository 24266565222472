import styled, { css } from 'styled-components'
import { Flex, Text } from '@magicsoup.io/stock'

const SliderMenu = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  :hover, :focus, :active {
    color: ${props => props.theme.colors.hover};
    outline: none;
  }

  :after {
    border-bottom: solid 3px ${props => props.theme.colors.hover};
    box-sizing: border-box;
    content: '';
    display: flex;
    flex-grow: 1;
    padding-bottom: 8px;
    transform: scaleX(0);  
    transition: transform 450ms ease-in-out;
  }
  :hover {
    :after { 
      transform: scaleX(1);
      width: 100%
    }
  }
  .slick-active < * {
    background-color: green;
  }
`

const Item = styled(Text)`
  display: block;
`
const Description = styled(Text)``

SliderMenu.defaultProps = {
  as: 'div',
}

Item.defaultProps = {
  as: 'span',
  fontSize: 3
}

Description.defaultProps = {
  as: 'span',
  fontSize: 3
}

SliderMenu.Item = Item
SliderMenu.Description = Description

export default SliderMenu
