import React, { Fragment } from "react";
import SlickSlider from "react-slick";
import { translate } from "react-i18next"
import uniqid from 'uniqid'
import { StaticQuery, graphql } from "gatsby"
import { Box, Card, Flex, Heading, Text } from '@magicsoup.io/stock'
import { Button, SliderMenu, SliderWrap, StoryElement, MobileDecider } from "../../styled";
import { ScrollToActionElement } from '@zauberware/react-scroll-to'

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  dots: false,
  arrows: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        focusOnSelect: true
      }
    }
  ]
}

const settingsNav = {
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  focusOnSelect: true,
  adaptiveHeight: true,
  dots: false,
  arrows: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        focusOnSelect: true,
        arrows: true,
      }
    }
  ]
}


class StorySlider extends React.Component {
  state = {
    nav: null,
    slider: null,
  }
  constructor(props) {
    super(props);
    this.state = {
      nav: null,
      slider: null
    };
  }

  componentDidMount() {
    this.setState({
      nav: this.nav,
      slider: this.slider,
    })

    this.handleStoryNext = this.handleStoryNext.bind(this)
  }

  handleStoryNext() {
    const { slider } = this.state
    if (slider) {
      // console.log("Switch Story")
      this.state.slider.slickNext()
    } else {
      // console.log("Error changing story.")
    }
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            storiesJson {
              storiesNext
              storiesHeadline
              stories {
                title
                description
                cards {
                  title
                  story
                }
              }
            }
          }
        `}
        render={data =>
          <Fragment>
            <Heading as="h1" variant="h1" fontSize={[7,7,11,14]}>{this.props.t(data.storiesJson.storiesHeadline)}</Heading>
            <SliderWrap width={[1, 1, 3/4]} mx='auto' pt={[6]}>
              <SlickSlider ref={slider => (this.nav = slider)} asNavFor={this.state.slider} { ...settingsNav}>
                {data.storiesJson.stories.map((story, index) => (
                    <SliderMenu
                      p={[2]}
                      width={[1/3]}
                      onClick={(e) => this.slider.slickGoTo(index)} key={uniqid()}
                      className="menuElement"
                    >
                      <SliderMenu.Item fontSize={[3,5,5,7]}>{this.props.t(story.title)}</SliderMenu.Item>
                      <SliderMenu.Description fontSize={[3,5,5,7]}>{this.props.t(story.description)}</SliderMenu.Description>
                    </SliderMenu>
                  ))}
              </SlickSlider>
            </SliderWrap>

            <SlickSlider ref={slider => (this.slider = slider)} asNavFor={this.state.nav}  {...settings}>
                {data.storiesJson.stories.map((slide, index) => (
                  <Flex flex={1} key={uniqid()} flexDirection="row">
                    <Flex width={5/6} mx={"auto"} flexDirection="row">

                      <MobileDecider flex={1} desktop>
                        <Flex width={1/2} flexDirection="column" justifyContent="space-evenly">
                          {slide.cards.map((card, i) => !(i % 2) ? (
                            <StoryElement
                              key={uniqid()}
                              width={[1]}
                              p={[3]}
                              >
                              <Flex 
                                bg='lightGray'
                                flexDirection="column"
                                >
                                <Heading as="h3" variant="slider" fontSize={[8,8,15,15]} p={[4]}>{this.props.t(card.title)}</Heading>
                                <Text 
                                  width={[1]}
                                  fontFamily='secondary'
                                  fontSize={[2,4,8,8]}
                                  px={[4]}
                                  pt={[6]}
                                  pb={[4]}
                                  fontWeight='350'
                                  alignSelf="flex-start">
                                    {this.props.t(card.story)}
                                  </Text>
                              </Flex>
                            </StoryElement>
                          )
                          :
                          null)}
                        </Flex>
                        <Flex width={1/2} flexDirection="column" justifyContent="space-evenly">
                          {slide.cards.map((card, ind) => ind % 2 ? (
                            <StoryElement
                              key={uniqid()}
                              width={[1]}
                              p={[3]}
                              >
                              <Flex 
                                bg='lightGray'
                                flexDirection="column"
                                >
                                <Heading as="h3" variant="slider" fontSize={[8,8,15,15]} p={[4]}>{this.props.t(card.title)}</Heading>
                                <Text 
                                  width={[1]}
                                  fontFamily='secondary'
                                  fontSize={[2,4,8,8]}
                                  px={[4]}
                                  pt={[6]}
                                  pb={[4]}
                                  fontWeight='350'
                                  alignSelf="flex-start">
                                    {this.props.t(card.story)}
                                  </Text>
                              </Flex>
                            </StoryElement>
                          )
                          :
                          null)}
                        </Flex>
                      </MobileDecider>

                      <MobileDecider flex={1} mobile>
                        <Flex width={1} flexDirection="column" justifyContent="space-evenly">
                          {slide.cards.map((card, im) => (
                            <StoryElement
                              key={uniqid()}
                              width={[1]}
                              p={[3]}
                              >
                              <Flex 
                                bg='lightGray'
                                flexDirection="column"
                                >
                                <Heading as="h3" variant="slider" fontSize={[8,8,15,15]} p={[4]}>{this.props.t(card.title)}</Heading>
                                <Text 
                                  width={[1]}
                                  fontFamily='secondary'
                                  fontSize={[2,2,6,6]}
                                  px={[4]}
                                  pt={[9]}
                                  pb={[4]}
                                  fontWeight='350'
                                  alignSelf="flex-start">
                                    {this.props.t(card.story)}
                                  </Text>
                              </Flex>
                            </StoryElement>
                          ))}
                        </Flex>
                      </MobileDecider>


                    </Flex>
                  </Flex>
                ))}
            </SlickSlider>
            <Flex alignItems="center" justifyContent="center" pt={[6]}>
              <ScrollToActionElement
                target='story'
                Parent={Button}
                variant='secondary'
                onMouseUp={() => this.handleStoryNext()}
              >
                <Text
                  p={[3]}
                  fontSize={[3,5,5,8]}  
                  fontWeight="400"  
                  // onClick={() => this.handleStoryNext()}
                  >
                  + {this.props.t(data.storiesJson.storiesNext)}
                </Text>
              </ScrollToActionElement>
            </Flex>
          </Fragment>
        }
      />
    )
  }
}

export default translate('stories')(StorySlider)