import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Header, Footer, CookieDisplay } from '../index'


const Layout = ({ children }) => (
  <Fragment>
    <Header />
    <main>{children}</main>
    <Footer />
    <CookieDisplay />
  </Fragment>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
