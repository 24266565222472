import React, { Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Icon } from '@zauberware/react-svg-assets'


import {
  Flex,
  Box,
  Heading,
  Text,
  Image,
} from '@magicsoup.io/stock'

import { TextVariant, ContactImage } from '../../styled'
import ContactImg from '../../images/fixit_felix.jpg'

import { ContactForm } from '../../components'
import { translate } from 'react-i18next';

class Contact extends React.Component {
  render() {
    return(
      <StaticQuery
        query={graphql`
          query ContactQuery {
            generalJson {
              contactTitle
              contactHeadline
              contactPhone
              contactFax
              contactMail
              contactSubtitle
              contactInformation
              contactAgentName
              contactAgentDetail
            }
          }`
        }
        render={ (data) => (
          <Fragment>
            <Box alignSelf='flex-center' width={[1, 1/2]} mx='auto' pb={[4]}>
              <Heading as="h2" variant="h2" fontSize={[4,5,5,9]}>{this.props.t(data.generalJson.contactTitle)}</Heading>
              <Heading as="h1" variant="h1" fontSize={[9,15,15,22]}>{this.props.t(data.generalJson.contactHeadline)}</Heading>
            </Box>
            <Flex flexWrap='wrap'>
              <Flex width={[1, 1, 1/3]}
                justifyContent={['center','center','flex-end']}
                >
                <Flex width={[1, 4/5]}
                  alignItems='center'
                  flexDirection='column'
                  p={[4]}
                  >
                  {/* <Image src={ContactImage} borderRadius="50%" maxWidth="100%" width={[2/3]} css={{heigth: unset}}/> */}
                  {/* <ContactImage src={ContactImg} width={[2/3]} /> */}
                  <TextVariant variant="headline" textAlign='center' p='0' pt='4' fontSize={[4,6,6,8]}>
                    {data.generalJson.contactAgentName}
                  </TextVariant>
                  <TextVariant variant="running" textAlign='center' p='0' pt='2' pb='4' fontSize={[2,3,3,6]} width={[1]}>
                    {this.props.t(data.generalJson.contactAgentDetail)}
                  </TextVariant>
                </Flex>
              </Flex>
              <Flex 
                bg='lightGray'
                flexDirection='column'
                p={[3]}
                width={[1, 1, 2/3]}
                >
                  <Flex alignItems="center" pb={[4]}>
                    <Box p={[2]}>
                      <Icon icon='phone' size={[2]}/>
                    </Box>
                    <Heading as="h2" variant="contact" fontSize={[7,12,12,18]}>
                      {data.generalJson.contactPhone}
                    </Heading>
                  </Flex>
                  <Flex alignItems="center" pb={[4]}>
                    <Box p={[2]}>
                      <Icon icon='fax' size={[2]} />
                    </Box>
                    <Heading as="h2" variant="contact" fontSize={[7,12,12,18]}>
                      {data.generalJson.contactFax}
                    </Heading>
                  </Flex>
                  <Flex alignItems="center" pb={[4]}>
                    <Box p={[2]}>
                      <Icon icon='mail' size={[2]} />
                    </Box>
                    <Heading as="h2" variant="contact" fontSize={[7,12,12,18]}>
                      {data.generalJson.contactMail}
                    </Heading>
                  </Flex>
              </Flex>
              <Flex flexWrap='wrap-reverse'>  
                <Box width={[1, 1, 2/3]}
                  bg='primary'>
                  <ContactForm />
                </Box>
                {/* <Box width={[1, 1, 1/3]} p={[3]}> */}
                  {/* <Text as='p' fontFamily='secondary' fontWeight='500' fontSize={[2,3,3,6]} m='0'>{data.generalJson.contactSubtitle}</Text>
                  <Text as='p' fontFamily='secondary' fontWeight='400' fontSize={[2,3,3,6]} m='0' pt={[2]}>{data.generalJson.contactInformation}</Text> */}
                {/* </Box> */}
              </Flex>
            </Flex>
          </Fragment>
        )}
      />
    )
  }
}

export default translate('contact')(Contact)
