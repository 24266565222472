// styled-components based extension
import styled from 'styled-components'
import { Box } from '@magicsoup.io/stock'

const IconAnimation = styled(Box)`
  opacity: 0;
  transition: opacity 200ms linear;

  ${ props => props.hover && `
    transition-delay: 500ms;
    transition: opacity 200ms linear;
    opacity: 1;
  `}
  
`

IconAnimation.defaultProps = {
  
}

export default IconAnimation