import React, { Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { translate } from 'react-i18next'

import CookieConsent from 'react-cookie-consent'

class CookieDisplay extends React.Component {
  render() {
    const componentStyles = {
      alignItems: "center",
      background: "#353535",
      color: "white",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      left: "0",
      position: "fixed",
      width: "100%",
      zIndex: "999"
    }
    const buttonStyles = {
      background: "#3c25f2",
      border: "0",
      borderRadius: "30px",
      boxShadow: "none",
      color: "white",
      cursor: "pointer",
      flex: "0 0 auto",
      fontWeight: "bold",
      padding: "15px 25px",
      margin: "15px"
    }
    const declineButtonStyles = {
      background: "#c12a2a",
      border: "0",
      borderRadius: "30px",
      boxShadow: "none",
      color: "#e5e5e5",
      cursor: "pointer",
      flex: "0 0 auto",
      fontWeight: "bold",
      padding: "15px 25px",
      margin: "15px"
    }
    const contentStyles = {
      flex: "1 1 auto",
      margin: "15px",
    }
    return (
      <StaticQuery
        query={graphql`
          query CookieQuery {
            privacyJson {
              cookie {
                text
                confirm
                deny
              }
            }
          }`
        }
        render={data => (
          <Fragment>
            <CookieConsent
              enableDeclineButton
              buttonText={this.props.t(data.privacyJson.cookie.confirm)}
              declineButtonText={this.props.t(data.privacyJson.cookie.deny)}
              cookieName="gatsby-gdpr-google-analytics"
              cookieValue="true"
              contentStyle={contentStyles}
              buttonStyle={buttonStyles}
              declineButtonStyle={declineButtonStyles}
              style={componentStyles}
            >
              {this.props.t(data.privacyJson.cookie.text)}
            </CookieConsent>
          </Fragment>
        )}
      />
    )
  }
}

export default translate('common')(CookieDisplay)
