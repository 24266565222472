import styled, { css } from 'styled-components'
import { Box, Flex, Text } from '@magicsoup.io/stock'

const Menu = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: nowrap;

`
const Nav = styled(Box)`
  white-space: nowrap;
  list-style: none;
`
const Item = styled(Text)`
  cursor: pointer;
  display: inline-block;
  :hover, :focus, :active {
    color: ${ props => props.theme.colors.hover};
  }
  a {
    text-decoration: none;
    color: ${ props => props.theme.colors.font};
    :hover, :focus, :active {
      color: ${ props => props.theme.colors.hover};
    }
  }
  ${props => props.active && `color: ${props.theme.colors.hover};`}
`

const Wrap = styled(Flex)`
  justify-content: space-between;
  position: fixed;
  background-color: white;
  box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.0);
  transition: all 500ms linear;
  z-index: 1001;
  flex-wrap:wrap;

  ${ props => props.scrolled && `
    background-color: white;
    //box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.25);
    transition: box-shadow 1000ms linear;
  `}

  ${ props => props.toggled && `
    background-color: transparent;
    box-shadow: none;
  `}

  @media (max-width: 968px) {
    .desktop {
      display: none;
    }
    .mobile {
      display: flex;
    }
  }   

  @media (min-width: 968px) {
    .desktop {
      display: flex;
    }
    .mobile {
      display: none;
    }
  }

  label.hamburg { 
    display: block;
    width: 36px; height: 36px; 
    position: relative; 
    margin-left: auto; margin-right: auto;
    border-radius: 4px; 
  }
 
  #hamburg {display:none}

  label {
    .line { 
      position: absolute; 
      left:2px;
      height: 4px; 
      width: 32px; 
      background: #000; 
      border-radius: 2px;
      display: block; 
      transition: 0.5s; 
      transform-origin: center; 
   }
   
    .line:nth-child(1) { top: 6px; }
    .line:nth-child(2) { top: 16px; }
    .line:nth-child(3) { top: 26px; }
  }

  #hamburg:checked + label.hamburg .line:nth-child(1){
    transform: translateY(10px) rotate(-45deg);
    background: #fff;
  }

  #hamburg:checked + label.hamburg .line:nth-child(2){
    opacity:0;
  }

  #hamburg:checked + label.hamburg .line:nth-child(3){
    transform: translateY(-10px) rotate(45deg);
    background: #fff;
  }

`

const Overlay = styled(Flex)`
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;


  ${props => props.isToggled && `
    display: flex;
    position: fixed;
    background-color: ${props.theme.colors.secondary};
    top: 0; bottom: 0;
    left: 0; right: 0;
    transition: all 1s linear;
  `}
  z-index: 1000;
  overflow: hidden;

  ${Nav} {
    width: 100%;
    text-align: center;
    ${Item} {
      display: block;
      color: #fff;
      transition: all 500ms linear;
      :hover, :active {
        color: ${ props => props.theme.colors.secondary};
        background-color: #fff;
        transition: all 500ms linear;
      }
    }
  }
`

Menu.defaultProps = {
  as: 'nav',
  p: 2,
}

Nav.defaultProps = {
  as: 'ul',
  p: 0,
  m: 0,
}

Item.defaultProps = {
  as: 'li',
  px: 3,
}

Menu.Wrap = Wrap
Menu.Overlay = Overlay
Menu.Nav = Nav
Menu.Item = Item

export default Menu
