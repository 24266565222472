// styled-components based extension
import styled from 'styled-components'
import { Flex, Box } from '@magicsoup.io/stock'

const Square = styled(Flex)`
  position: relative;
  overflow: hidden;
  :before {
    content:'';
    float:left;
    padding-top:100%;
  }
  
`

const Container = styled(Box)`
  position: relative;
  perspective: 1000;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: all 0.6s;

  ${ props => props.flipped && `
    transform: rotateY(180deg);
  `}
`

const Card = styled(Flex)`
  position: absolute;
  flex-direction: column;
  //display: block;
  backface-visibility: hidden;
  width: 100%;
  height: 100%;

  ${ props => props.back && `
    position: relative;
    //display: block;
    transform: rotateY(180deg);
    box-sizing: border-box;
  `}
`

Square.Card = Card
Square.Container = Container

// Square.defaultProps = {
//   py: 5
// }

export default Square