import React, { Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'

import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

import { Box, Flex, Text } from '@magicsoup.io/stock'
import { Button, TextVariant, StyledForm } from '../../styled'
import { translate } from 'react-i18next'

class ContactForm extends React.Component {

  handleTextChange(e){
    e.target.style.height = e.target.scrollHeight + 'px'
  }

  render() {
    const ContactSchema = Yup.object().shape({
      fullname: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
      email: Yup.string()
        .email('Invalid email')
        .required('Required'),
      consent: Yup.boolean()
        .oneOf([true], 'Must Accept Terms and Conditions'),
    })

    return(
      <StaticQuery
        query={graphql`
          query ContactFormQuery {
            formsJson {
              formTemplateIntro
              formTemplateName
              formTemplateRequest
              formTemplateEmail
              formTemplateCall
              formTemplateCallClosing
              formTemplateClosing
              formPrivacy
              formSubmit
            }
          }`
        }
        render={ (data) => (
          <Fragment>
            <Flex>
            <Formik
              initialValues={{
                fullname: '',
                email: '',
                phone: '',
                request: '',
                consent: false,
              }}
              validateOnChange
              validationSchema={ContactSchema}
              onSubmit={values => {
                // same shape as initial values
                // console.log(values);
              }}
            >
              {({ errors, touched, handleChange, isValid }) => (
                <StyledForm p={[5]} width={[1]}>
                  <Form action="https://formspree.io/philipp@zauberware.com">
                    <StyledForm.Section>
                      <TextVariant as='p' variant='form'
                        color='secondary' fontSize={[3,7,11,18]}
                      >
                        {this.props.t(data.formsJson.formTemplateIntro)}
                      </TextVariant>
                    </StyledForm.Section>

                    <StyledForm.Section>
                      <TextVariant as='p' variant='form' fontSize={[3,7,11,18]} lineHeight={['34px','40px', '1.5em', '69px']}>
                        {this.props.t(data.formsJson.formTemplateName)}
                        <Field name="fullname">
                          {({ field, form }) => (
                            <StyledForm.InputGroup>
                              <StyledForm.ErrorMessage fontSize={[0]} lineHeight={[0]} htmlFor="fullname"
                                error={errors.fullname && touched.fullname ? true : false}
                              >
                                {this.props.t("Bitte tragen Sie Ihren Namen ein.")}
                              </StyledForm.ErrorMessage>
                              <StyledForm.Input
                                type="text"
                                placeholder={!errors.fullname && !touched.fullname ? 'Name' : ''}
                                error={errors.fullname && touched.fullname ? true : false}
                                {...field}
                              />
                            </StyledForm.InputGroup>
                          )}
                        </Field>
                        {this.props.t(data.formsJson.formTemplateRequest)}
                        <Field name="request">
                          {({ field, form }) => (
                            <StyledForm.Input as="textarea" placeholder={this.props.t("Anfrage")} width={['100%']} onChange={(event) => this.handleTextChange(event)} textarea rows='1'/>
                          )}
                        </Field>
                        <br />
                        {this.props.t(data.formsJson.formTemplateEmail)}
                        <Field name="email">
                          {({ field, form }) => (
                            <StyledForm.InputGroup>
                              <StyledForm.ErrorMessage fontSize={[0]} lineHeight={[0]} htmlFor="email" 
                                error={errors.email && touched.email ?  true : false}
                              >
                                {this.props.t("Bitte geben Sie eine E-Mail Adresse an.")}
                              </StyledForm.ErrorMessage>
                              <StyledForm.Input
                                type="text"
                                placeholder={!errors.email && !touched.email ? 'Email' : '' }
                                error={errors.email && touched.email ? true : false}
                                {...field}
                              />
                            </StyledForm.InputGroup>
                          )}
                        </Field>.
                        {this.props.t(data.formsJson.formTemplateCall)}
                        <Field name="phone">
                          {({ field, form }) => (
                            <StyledForm.Input type="text" placeholder={this.props.t("Telefon")} {...field} />
                            )}
                        </Field>
                        {this.props.t(data.formsJson.formTemplateCallClosing)}<br />
                        {this.props.t(data.formsJson.formTemplateClosing)}
                      </TextVariant>
                    </StyledForm.Section>

                    <StyledForm.Section py={[3]} width={[1, 2/3, 2/3, 1/2]} flexWrap="wrap">
                      <StyledForm.Label checkbox error={errors.consent && touched.consent ?  true : false}>
                        <Field name="consent">
                          {({ field, form }) => (
                            <StyledForm.Input type="checkbox" {...field} onChange={handleChange} />
                          )}
                        </Field>
                        <Text as='p' p={[2]}  m={[0]} fontFamily='secondary' fontSize={[0,1,1,1]}>{this.props.t(data.formsJson.formPrivacy)}</Text>
                      </StyledForm.Label>
                      <StyledForm.ErrorMessage fontSize={[0]} lineHeight={[1]} htmlFor="consent" 
                        errorText={errors.consent ?  true : false}
                      >
                        {this.props.t("Bitte stimmen Sie der Verarbeitung Ihrer Daten nach DSGVO zu.")}
                      </StyledForm.ErrorMessage>
                    </StyledForm.Section>

                    <Box>
                      <Button variant={isValid ? 'primary': 'form'} pt='3' type="submit" disabled={!isValid ? 'disabled' : ''} >{this.props.t(data.formsJson.formSubmit)}</Button>
                    </Box>

                  </Form>
                </StyledForm>
              )}
              </Formik>
            </Flex>
          </Fragment>
        )}
      />
    )
  }
}

export default translate('contact')(ContactForm)
