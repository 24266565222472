import React from "react";
import SlickSlider from "react-slick"

import { StaticQuery, graphql } from "gatsby"
import { Link, Square, Testimonial } from '../../styled'
import { Heading, Blockquote } from '@magicsoup.io/stock'
import { translate } from "react-i18next";

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: false,
  arrows: false,
  dots: true
}

class TestimonialSlider extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            testimonialsJson {
              testimonials {
                quote
                author
                position
                target
              }
            }
          }
        `}
        render={data => 
          <SlickSlider {...settings}>
            { data.testimonialsJson.testimonials.map((testimonial, index) => (
              <Square p={[3]} key={index}>
                <Testimonial fontSize={[8,16,16,22]} lineHeight={['1.15em']}>{this.props.t(testimonial.quote)}</Testimonial>
                <Link href={testimonial.target}>
                  <Heading as="h5" variant="h5" fontSize={[2,2,5,6]}>{testimonial.author}{ this.props.t(testimonial.position) && `, ${this.props.t(testimonial.position)}`}</Heading>
                </Link>
              </Square>
            ))}
          </SlickSlider>
        }
      />
    )
  }
}

export default translate('about')(TestimonialSlider)