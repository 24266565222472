import React, { Component } from "react";
import { Icon } from "@zauberware/react-svg-assets";
import { StaticQuery, graphql, Link } from "gatsby";
import Modal from "react-modal";

import { translate } from "react-i18next";

import lottie from "lottie-web";
import { TimelineLite, TimelineMax, Linear } from "gsap";
import { Box, Flex, Text } from "@magicsoup.io/stock";
import gg_animation_footer from "./animation-logo-footer.json";

import { Container, Section, Menu, Close } from "../../styled";

import ImprintModal from "../Modals/ImprintModal";
import PrivacyPolicy from "../Modals/PrivacyPolicy";

const customStyles = {
  overlay: {
    zIndex: "1002",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};
Modal.setAppElement("body");
if (typeof window !== "undefined") {
  Modal.setAppElement("body");
}
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isImprintOpen: false,
      isPrivacyOpen: false,
    };
  }

  componentDidMount() {
    let last_known_scroll_position = 0;
    let ticking = false;
    const weakThis = this;

    window.addEventListener("scroll", (e) => {
      last_known_scroll_position = window.scrollY;

      if (!ticking) {
        window.requestAnimationFrame(() => {
          // console.log(`scrolling... ${window.scrollY}`)
          if (weakThis && weakThis._ggf) {
            const isDisplayed = weakThis.isInViewport(weakThis._ggf);
            if (isDisplayed) {
              if (!weakThis._gg_animation_footer) {
                weakThis._checkAnimationGgf();
              } else {
                weakThis._gg_animation_footer.play();
              }
            } else if (weakThis._gg_animation_footer) {
              weakThis._gg_animation_footer.pause();
            }
          }
          ticking = false;
        });

        ticking = true;
      }
    });
  }

  componentDidUpdate() {
    this._checkAnimationGgf();
  }

  _checkAnimationGgf = () => {
    if (this._ggf && !this._gg_animation_footer) {
      this._gg_animation_footer = lottie.loadAnimation({
        container: this._ggf,
        renderer: "svg",
        loop: true,
        autoplay: false,
        animationData: gg_animation_footer,
      });
    }
  };

  handleImprintOpen = (event) => {
    this.setState({
      isImprintOpen: true,
      isPrivacyOpen: false,
    });
  };

  handlePrivacyOpen = (event) => {
    this.setState({
      isImprintOpen: false,
      isPrivacyOpen: true,
    });
  };

  handleModalClose = (event) => {
    this.setState({
      isImprintOpen: false,
      isPrivacyOpen: false,
    });
  };

  isInViewport = (elem) => {
    const bounding = elem.getBoundingClientRect();
    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            generalJson {
              footer {
                company
                rights
                zauberware
              }
            }
          }
        `}
        render={(data) => (
          <Container px="4" className="footerContainer">
            <Section py={[4]}>
              <Flex
                id="ggf"
                width={[1 / 3]}
                mx="auto"
                ref={(ggf) => (this._ggf = ggf)}
              />
            </Section>

            <Section css={{ borderTop: "1px solid black" }} py="0">
              <Flex as="footer" flexWrap="wrap" pb="4">
                <Flex flex="1 0 100%">
                  <Menu>
                    <Menu.Nav>
                      <Menu.Item>
                        <Link to="/" onClick={this.handleImprintOpen}>
                          {this.props.t("Impressum")}
                        </Link>
                      </Menu.Item>
                      <Menu.Item>
                        <Link to="/" onClick={this.handlePrivacyOpen}>
                          {this.props.t("Datenschutz")}
                        </Link>
                      </Menu.Item>
                    </Menu.Nav>
                  </Menu>
                </Flex>
                <Box width={[1, 1, 1 / 2]}>
                  <Text
                    fontSize={[0, 0, 2, 2]}
                    fontFamily="secondary"
                    as="p"
                    color="#646472"
                  >
                    &copy; {data.generalJson.footer.company}{" "}
                    {new Date().getFullYear()}. {data.generalJson.footer.rights}
                    .
                  </Text>
                </Box>
                <Box width={[1, 1, 1 / 2]}>
                  <Text
                    fontSize={[0, 0, 2, 2]}
                    fontFamily="secondary"
                    as="p"
                    color="#646472"
                    textAlign="right"
                  >
                    {/* {data.generalJson.footer.zauberware} */}
                  </Text>
                </Box>
              </Flex>
            </Section>
            <Modal
              isOpen={this.state.isImprintOpen}
              onRequestClose={this.handleModalClose}
              contentLabel="Imprint"
              style={customStyles}
            >
              <ImprintModal />
              <Close>
                <input
                  type="checkbox"
                  id="munich"
                  checked="checked"
                  onClick={this.handleModalClose}
                  onChange={this.handleModalClose}
                />
                <label htmlFor="munich" className="munich">
                  <span className="line" />
                  <span className="line" />
                  <span className="line" />
                </label>
              </Close>
            </Modal>
            <Modal
              isOpen={this.state.isPrivacyOpen}
              onRequestClose={this.handleModalClose}
              contentLabel="Privacy"
              style={customStyles}
            >
              <PrivacyPolicy />
              <Close>
                <input
                  type="checkbox"
                  id="munich"
                  checked="checked"
                  onClick={this.handleModalClose}
                  onChange={this.handleModalClose}
                />
                <label htmlFor="munich" className="munich">
                  <span className="line" />
                  <span className="line" />
                  <span className="line" />
                </label>
              </Close>
            </Modal>
          </Container>
        )}
      />
    );
  }
}

export default translate("legal")(Footer);
